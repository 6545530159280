.loader {
  position: absolute;
  width: 64px;
  height: 60px;
}
.loader::after {
  @apply bg-primaryLight;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 64px;
  height: 32px;
  border-radius: 0 0 50px 50px;
  animation: move 0.5s linear infinite alternate;
}
.loader::before {
  @apply bg-accentMain;
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  width: 24px;
  height: 24px;
  transform: translateX(-50%) rotate(0deg);
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
@keyframes move {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}
