.btn {
  @apply flex h-12 max-w-fit cursor-pointer items-center justify-center rounded-base border-2 border-accentMain bg-accentMain px-btnX py-btnY text-lg font-normal text-white transition-colors;
}
.btn:hover,
.btn:focus {
  @apply border-accentAdditional bg-accentAdditional;
}

.btn:active {
  @apply border-2 border-accentMain bg-accentAdditional text-white;
}

.btn:disabled {
  @apply cursor-not-allowed border-disabledBg bg-disabledBg text-disabledText;
}

/* .large {
  @apply relative pr-[52px];
} */

.btn.white {
  @apply border border-white bg-white text-accentMain;
}

.btn.white:hover,
.btn.white:focus,
.btn.white:active {
  @apply bg-transparent text-white;
}

/* .btn.white:active {
  @apply border-white bg-white text-accentMain;
} */

.btn.white.transparent {
  @apply bg-transparent text-white;
}

.btn.white.transparent:hover,
.btn.white.transparent:focus,
.btn.white.transparent:active {
  @apply bg-white text-accentMain;
}
